import FormErrorController from '../FormErrorController';
export default class RequireAtLeastOneFile {
    constructor(checkboxSelector) {
        this.checkboxSelector = checkboxSelector;
    }
    onSubmit() {
        return this.reportValidity();
    }
    reportValidity() {
        var _a;
        const fileInputs = document.querySelectorAll('input[type="file"]');
        let isFileSelected = false;
        for (const fileInput of fileInputs) {
            if (((_a = fileInput.files) === null || _a === void 0 ? void 0 : _a.length) > 0) {
                isFileSelected = true;
                break;
            }
        }
        if (isFileSelected) {
            // all good
            FormErrorController.removeError(this.checkboxSelector);
        }
        else {
            const errorMessage = 'Bitte kreuzen Sie bei Punkt 2. entsprechend an' +
                ' und laden Sie die Nachweise hoch. ' +
                'Ohne Angaben und Nachweise ist keine Ermäßigung möglich.';
            const form = document.querySelector(this.checkboxSelector);
            if (!form)
                return console.error('RequireAtLeaseOneField: Form not found');
            FormErrorController.addError(form, errorMessage);
            return errorMessage;
        }
    }
    start() {
        const fileInputs = document.querySelectorAll('input[type="file"]');
        for (const fileInput of fileInputs) {
            fileInput.addEventListener('change', () => {
                this.reportValidity();
            });
        }
    }
}
