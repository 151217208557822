var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { hideSearchOnFormPages } from './FormUtils';
import SchulgeldGeschwisterBonus from './schulgeld/GeschwisterBonus';
import SchulgeldSchulgeldErmaessigung from './schulgeld/SchulgeldErmaessigung';
import SchulgeldSchulgeldErmaessigungMitGeschwisterbonus from './schulgeld/SchulgeldErmaessigungMitGeschwisterbonus';
const COMMON_ID_GESCHWISTER = 'geschwisterbonus-1208';
const COMMON_ID_SCHULDGELDERMAESSIGUNG = 'schulgeldermaessigung-1207';
var FORM_PATHS;
(function (FORM_PATHS) {
    FORM_PATHS["GESCHWISTERBONUS"] = "/geschwisterbonus";
    FORM_PATHS["SCHULDGELDERMAESSIGUNG"] = "/schulgeldermaessigung";
})(FORM_PATHS || (FORM_PATHS = {}));
const { pathname } = new URL(window.location.href);
hideSearchOnFormPages([
    FORM_PATHS.GESCHWISTERBONUS,
    FORM_PATHS.SCHULDGELDERMAESSIGUNG
]);
export const initPDFForm = () => __awaiter(void 0, void 0, void 0, function* () {
    autofillHelper();
    if (SchulgeldGeschwisterBonus.isPresentOnPage()) {
        new SchulgeldGeschwisterBonus();
    }
    else if (SchulgeldSchulgeldErmaessigung.isPresentOnPage()) {
        new SchulgeldSchulgeldErmaessigung();
    }
    else if (SchulgeldSchulgeldErmaessigungMitGeschwisterbonus.isPresentOnPage()) {
        new SchulgeldSchulgeldErmaessigungMitGeschwisterbonus();
    }
});
const autofillHelper = () => {
    // Set postal code field type
    if (pathname === FORM_PATHS.GESCHWISTERBONUS) {
        const postalCodeField = document.querySelector(`#${COMMON_ID_GESCHWISTER}-text-3`);
        if (postalCodeField) {
            postalCodeField.autocomplete = 'postal-code';
            postalCodeField.type = 'number';
        }
        const cityField = document.querySelector(`#${COMMON_ID_GESCHWISTER}-text-32`);
        if (cityField) {
            cityField.autocomplete = 'address-level2';
        }
    }
    if (pathname === FORM_PATHS.SCHULDGELDERMAESSIGUNG) {
        const postalCodeField = document.querySelector(`#${COMMON_ID_SCHULDGELDERMAESSIGUNG}-text-3`);
        if (postalCodeField) {
            postalCodeField.autocomplete = 'postal-code';
            postalCodeField.type = 'number';
        }
        const cityField = document.querySelector(`#${COMMON_ID_SCHULDGELDERMAESSIGUNG}-text-37`);
        if (cityField) {
            cityField.autocomplete = 'address-level2';
        }
        const bdayField = document.querySelector(`#${COMMON_ID_SCHULDGELDERMAESSIGUNG}-text-9`);
        if (bdayField) {
            bdayField.autocomplete = 'bday';
        }
    }
};
