/**
* Scales the text to fit within the container.
* This function gets the size of the container and the text,
* calculates the scale factor as the minimum of the ratio of the container's width to the text's width
* and the ratio of the container's height to the text's height,
* applies the scale to the text, and sets the transform origin to the center of the text.
* This function is also set up to re-apply the scale whenever the window is resized.
*/
const scaleSignatureToFitContainer = () => {
    // Get the container and the text element
    const container = document.querySelector('.intro__signature');
    const textElement = document.querySelector('.intro__signature span');
    if (!container || !textElement) {
        return;
    }
    textElement.style.transform = 'unset';
    textElement.style.transformOrigin = 'unset';
    // Get the size of the container and the text element
    const containerRect = container.getBoundingClientRect();
    const textRect = textElement.getBoundingClientRect();
    // Calculate the scale factor
    const scale = containerRect.width / textRect.width;
    // Apply the scale to the text element
    textElement.style.transform = `scale(${scale})`;
    textElement.style.transformOrigin = 'center right';
};
export const initSignature = () => {
    // Apply the scale immediately and whenever the window is resized
    window.addEventListener('resize', scaleSignatureToFitContainer);
    setTimeout(() => {
        scaleSignatureToFitContainer();
    }, 50);
    const interval = setInterval(() => {
        scaleSignatureToFitContainer();
    }, 300);
    setTimeout(() => {
        clearInterval(interval);
    }, 2000);
};
