export const hideSearchOnFormPages = (pages) => {
    if (pages.includes(window.location.pathname)) {
        const mobileSearch = document.querySelector('.js-mobile-search');
        const desktopSearch = document.querySelector('.search-toggle.js-search');
        mobileSearch.style.display = 'none';
        desktopSearch.style.visibility = 'hidden';
    }
};
export const turnOffAutocomplete = (selectors) => {
    for (const selector of selectors) {
        const element = document.querySelector(selector);
        if (!element)
            continue;
        element.autocomplete = 'off-xyz';
    }
};
export const getAge = (date) => {
    const today = new Date();
    let age = today.getFullYear() - date.getFullYear();
    const month = today.getMonth() - date.getMonth();
    if (month < 0 || (month === 0 && today.getDate() < date.getDate())) {
        age--;
    }
    return age;
};
export const isElementDisplayed = (element) => element.offsetParent !== null &&
    element.getBoundingClientRect().width > 0 && element.getBoundingClientRect().height > 0;
