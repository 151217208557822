import GUI from 'lil-gui';
export class ParamsDebugger {
    constructor({ show }) {
        this.state = {
            colorPrimary: getComputedStyle(document.documentElement)
                .getPropertyValue('--color-primary')
                .trim()
                .replace(/['"]+/g, '') || '',
            colorPrimaryDark: getComputedStyle(document.documentElement)
                .getPropertyValue('--color-primary-dark')
                .trim()
                .replace(/['"]+/g, '') || '',
            colorPrimaryLight: getComputedStyle(document.documentElement)
                .getPropertyValue('--color-primary-light')
                .trim()
                .replace(/['"]+/g, '') || '',
            colorPrimaryLightBg: getComputedStyle(document.documentElement)
                .getPropertyValue('--color-primary-light-bg')
                .trim()
                .replace(/['"]+/g, '') || '',
            colorPrimaryLighter: getComputedStyle(document.documentElement)
                .getPropertyValue('--color-primary-lighter')
                .trim()
                .replace(/['"]+/g, '') || '',
            colorPrimaryLighterBg: getComputedStyle(document.documentElement)
                .getPropertyValue('--color-primary-lighter-bg')
                .trim()
                .replace(/['"]+/g, '') || '',
            colorPrimarySemilightBg: getComputedStyle(document.documentElement)
                .getPropertyValue('--color-primary-semilight-bg')
                .trim()
                .replace(/['"]+/g, '') || '',
            colorPrimarySelected: getComputedStyle(document.documentElement)
                .getPropertyValue('--color-primary-selected')
                .trim()
                .replace(/['"]+/g, '') || '',
            colorSecondary: getComputedStyle(document.documentElement)
                .getPropertyValue('--color-secondary')
                .trim()
                .replace(/['"]+/g, '') || '',
            colorSecondaryDark: getComputedStyle(document.documentElement)
                .getPropertyValue('--color-secondary-dark')
                .trim()
                .replace(/['"]+/g, '') || '',
            colorSecondaryLight: getComputedStyle(document.documentElement)
                .getPropertyValue('--color-secondary-light')
                .trim()
                .replace(/['"]+/g, '') || '',
            colorSecondaryLighter: getComputedStyle(document.documentElement)
                .getPropertyValue('--color-secondary-lighter')
                .trim()
                .replace(/['"]+/g, '') || '',
            colorSecondaryLighterBg: getComputedStyle(document.documentElement)
                .getPropertyValue('--color-secondary-lighter-bg')
                .trim()
                .replace(/['"]+/g, '') || '',
            colorMenu: getComputedStyle(document.documentElement)
                .getPropertyValue('--color-menu')
                .trim()
                .replace(/['"]+/g, '') || '',
            colorText: getComputedStyle(document.documentElement)
                .getPropertyValue('--color-text')
                .trim()
                .replace(/['"]+/g, '') || ''
        };
        const windowWidth = window.innerWidth;
        this.show = show;
        if (!this.show || windowWidth < 1024)
            return;
        this.gui = new GUI();
        this.addPrimaryColorFolder();
        this.addSecondaryColorFolder();
        this.addOthersColorFolder();
    }
    addPrimaryColorFolder() {
        const primaryColorFolder = this.gui.addFolder('Primary Colors');
        primaryColorFolder
            .addColor(this.state, 'colorPrimary')
            .name('Color_Primary')
            .onChange((value) => {
            this.state.colorPrimary = value;
            document.documentElement.style.setProperty('--color-primary', value);
        });
        primaryColorFolder
            .addColor(this.state, 'colorPrimaryDark')
            .name('Color_Primary_Dark')
            .onChange((value) => {
            this.state.colorPrimaryDark = value;
            document.documentElement.style.setProperty('--color-primary-dark', value);
        });
        primaryColorFolder
            .addColor(this.state, 'colorPrimaryLight')
            .name('Color_Primary_Light')
            .onChange((value) => {
            this.state.colorPrimaryLight = value;
            document.documentElement.style.setProperty('--color-primary-light', value);
        });
        primaryColorFolder
            .addColor(this.state, 'colorPrimaryLightBg')
            .name('Color_Primary_Light_Bg')
            .onChange((value) => {
            this.state.colorPrimaryLightBg = value;
            document.documentElement.style.setProperty('--color-primary-light-bg', value);
        });
        primaryColorFolder
            .addColor(this.state, 'colorPrimaryLighter')
            .name('Color_Primary_Lighter')
            .onChange((value) => {
            this.state.colorPrimaryLighter = value;
            document.documentElement.style.setProperty('--color-primary-lighter', value);
        });
        primaryColorFolder
            .addColor(this.state, 'colorPrimaryLighterBg')
            .name('Color_Primary_Lighter_Bg')
            .onChange((value) => {
            this.state.colorPrimaryLighterBg = value;
            document.documentElement.style.setProperty('--color-primary-lighter-bg', value);
        });
        primaryColorFolder
            .addColor(this.state, 'colorPrimarySemilightBg')
            .name('Color_Primary_Semilight_Bg')
            .onChange((value) => {
            this.state.colorPrimarySemilightBg = value;
            document.documentElement.style.setProperty('--color-primary-semilight-bg', value);
        });
        primaryColorFolder
            .addColor(this.state, 'colorPrimarySelected')
            .name('Color_Primary_Selected')
            .onChange((value) => {
            this.state.colorPrimarySelected = value;
            document.documentElement.style.setProperty('--color-primary-selected', value);
        });
    }
    addSecondaryColorFolder() {
        const secondaryColorFolder = this.gui.addFolder('Secondary Colors');
        secondaryColorFolder
            .addColor(this.state, 'colorSecondary')
            .name('Color_Secondary')
            .onChange((value) => {
            this.state.colorSecondary = value;
            document.documentElement.style.setProperty('--color-secondary', value);
        });
        secondaryColorFolder
            .addColor(this.state, 'colorSecondaryDark')
            .name('Color_Secondary_Dark')
            .onChange((value) => {
            this.state.colorSecondaryDark = value;
            document.documentElement.style.setProperty('--color-secondary-dark', value);
        });
        secondaryColorFolder
            .addColor(this.state, 'colorSecondaryLight')
            .name('Color_Secondary_Light')
            .onChange((value) => {
            this.state.colorSecondaryLight = value;
            document.documentElement.style.setProperty('--color-secondary-light', value);
        });
        secondaryColorFolder
            .addColor(this.state, 'colorSecondaryLighter')
            .name('Color_Secondary_Lighter')
            .onChange((value) => {
            this.state.colorSecondaryLighter = value;
            document.documentElement.style.setProperty('--color-secondary-lighter', value);
        });
        secondaryColorFolder
            .addColor(this.state, 'colorSecondaryLighterBg')
            .name('Color_Secondary_Lighter_Bg')
            .onChange((value) => {
            this.state.colorSecondaryLighterBg = value;
            document.documentElement.style.setProperty('--color-secondary-lighter-bg', value);
        });
        secondaryColorFolder.close();
    }
    addOthersColorFolder() {
        const othersColorFolder = this.gui.addFolder('Other Colors');
        othersColorFolder
            .addColor(this.state, 'colorMenu')
            .name('Color_Menu')
            .onChange((value) => {
            this.state.colorMenu = value;
            document.documentElement.style.setProperty('--color-menu', value);
        });
        othersColorFolder
            .addColor(this.state, 'colorText')
            .name('Color_Text')
            .onChange((value) => {
            this.state.colorText = value;
            document.documentElement.style.setProperty('--color-text', value);
        });
        othersColorFolder.close();
    }
}
