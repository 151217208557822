export default class EnforceSingleCheckbox {
    constructor(selector, message) {
        this.selector = selector;
        this.message = message;
    }
    start() {
        const container = document.querySelector(this.selector);
        if (!container) {
            console.warn(`No element found with the selector: ${this.selector}`);
            return;
        }
        container.addEventListener('click', (event) => {
            const checkboxes = container.querySelectorAll('input[type="checkbox"]');
            const target = event.target;
            if (target.tagName !== 'INPUT' || target.type !== 'checkbox') {
                return;
            }
            const checkedCheckboxes = Array.from(checkboxes).filter((cb) => cb.checked);
            if (checkedCheckboxes.length > 1) {
                alert(this.message);
                event.preventDefault(); // Prevent the checkbox from being checked
                target.checked = false; // Uncheck the newly clicked checkbox
            }
        });
    }
}
