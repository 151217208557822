import { isElementDisplayed } from '../FormUtils';
import FormErrorController from '../FormErrorController';
export default class EnforceMaxInputTextLength {
    constructor(selector, maxChars) {
        this.selector = selector;
        this.maxChars = maxChars;
        this.field = document.querySelector(selector);
    }
    start() {
        if (!this.field)
            return console.error('enforceMaxInputTextLength: Element not found', this.selector);
        this.field.addEventListener('input', (e) => {
            const field = e.target;
            this.reportValidity(field);
        });
    }
    reportValidity(field = this.field) {
        if (!isElementDisplayed(field)) {
            FormErrorController.removeError(field);
            return;
        }
        if (field.value.length > this.maxChars) {
            const error = `Maximal ${this.maxChars} Zeichen. Möchten Sie uns mehr mitteilen, dann hängen Sie bitte ein zusätzliches Dokument an den Antrag an.`;
            FormErrorController.addError(field, error);
            return error;
        }
        FormErrorController.removeError(field);
    }
    onSubmit() {
        if (!this.field)
            return console.error('enforceMaxInputTextLength: Element not found', this.selector);
        return this.reportValidity(this.field);
    }
}
