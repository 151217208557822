import FormErrorController from '../FormErrorController';
const { pathname } = new URL(window.location.href);
var FORM_PATHS;
(function (FORM_PATHS) {
    FORM_PATHS["GESCHWISTERBONUS"] = "/geschwisterbonus";
    FORM_PATHS["SCHULDGELDERMAESSIGUNG"] = "/schulgeldermaessigung";
})(FORM_PATHS || (FORM_PATHS = {}));
let radioButtonsSelectorWrapper = '#geschwisterbonus-1208-radiobutton-2';
if (pathname === FORM_PATHS.SCHULDGELDERMAESSIGUNG) {
    radioButtonsSelectorWrapper = '#schulgeldermaessigung-1207-radiobutton-2';
}
export class ManageIban {
    constructor(selector = radioButtonsSelectorWrapper) {
        this.selector = selector;
    }
    start() {
        const ibanInput = document.querySelector('.input-iban');
        ManageIban.manageField(ibanInput);
        ibanInput.addEventListener('blur', () => {
            const makeRadioButtonsRequired = document.querySelector(this.selector);
            const radioInputs = makeRadioButtonsRequired.querySelectorAll('input');
            for (const radio of radioInputs) {
                radio.required = true;
            }
            const errorMessage = ManageIban.validateIBAN(ibanInput.value);
            if (errorMessage && ibanInput.value) {
                if (ibanInput.required) {
                    FormErrorController.addError(ibanInput, errorMessage);
                }
            }
            else {
                FormErrorController.removeError(ibanInput);
            }
        });
    }
    static validateIBAN(iban) {
        // Remove spaces for validation
        const cleanedIban = iban.replace(/\s+/g, '');
        // Regular expression for basic IBAN validation
        const regex = /^([A-Z]{2}[0-9]{2})([0-9A-Z]{11,30})$/;
        // Check if IBAN is only 'DE'
        if (cleanedIban === 'de' || cleanedIban === 'DE') {
            return 'Bitte geben Sie eine gültige IBAN im korrekten Format ein.';
        }
        // Check if the IBAN matches the regex pattern
        if (!regex.test(cleanedIban)) {
            return 'Bitte geben Sie eine gültige IBAN im korrekten Format ein.';
        }
        // Check if the IBAN length is not more than 22 characters
        if (cleanedIban.length > 22) {
            return 'Die IBAN darf nicht länger als 22 Zeichen sein.';
        }
        // If all checks pass, return an empty string (no error)
        return '';
    }
    static manageField(field) {
        if (!field)
            return console.error('manageIbanField: Element not found', '#iban');
        let lastValidValue = 'DE';
        field.value = 'DE';
        field.addEventListener('input', (e) => {
            const field = e.target;
            if ('' === field.value) {
                lastValidValue = 'DE';
                return (field.value = 'DE');
            }
            if (field.value.startsWith('DE')) {
                lastValidValue = field.value;
            }
            else {
                field.value = lastValidValue;
            }
        });
    }
}
