// biome-ignore lint/complexity/noStaticOnlyClass: <explanation>
export default class FormErrorController {
    static hasErrors() {
        return FormErrorController.errors.size > 0;
    }
    static addError(selector, message) {
        var _a, _b;
        let element;
        if (typeof selector === 'object') {
            element = selector;
            FormErrorController.errors.set(`#${selector.id}`, message);
        }
        else if (typeof selector === 'string') {
            selector = selector.trim();
            element = document.querySelector(selector);
            if (!element) {
                return console.error('addErrorToFormElement: Element not found', selector);
            }
            FormErrorController.errors.set(selector, message);
        }
        else {
            return console.error('addErrorToFormElement: Invalid selector', selector);
        }
        if (!element)
            return console.error('addErrorToFormElement: Element not found', selector);
        let errorElement = (_a = element.parentElement) === null || _a === void 0 ? void 0 : _a.querySelector('.error');
        if (!errorElement) {
            errorElement = document.createElement('span');
            errorElement.innerHTML = message;
            (_b = element.parentElement) === null || _b === void 0 ? void 0 : _b.appendChild(errorElement);
        }
        else {
            errorElement.innerHTML = message;
        }
        errorElement.classList.add('help-block', 'error', element.id);
        element.setCustomValidity(message);
    }
    static removeError(selector) {
        var _a;
        let element;
        if (typeof selector === 'object') {
            element = selector;
            FormErrorController.errors.delete('#' + selector.id);
        }
        else if (typeof selector === 'string') {
            selector = selector.trim();
            element = document.querySelector(selector);
            if (!element) {
                return console.error('removeErrorToFormElement: Element not found', selector);
            }
            FormErrorController.errors.delete(selector);
        }
        else {
            return console.error('removeErrorToFormElement: Invalid selector', selector);
        }
        if (!element)
            return console.error('removeErrorFromFormElement: Element not found', selector);
        const errorElement = (_a = element.parentElement) === null || _a === void 0 ? void 0 : _a.querySelector('.error.help-block');
        if (!errorElement)
            return;
        errorElement.remove();
        element.setCustomValidity('');
    }
    static scrollToErroredField(field, e) {
        e.preventDefault();
        const rect = field.getBoundingClientRect();
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        const top = rect.top + scrollTop;
        window.scrollTo({
            top: top - 200,
            behavior: 'smooth'
        });
    }
    static update() {
        for (const [selector] of FormErrorController.errors.entries()) {
            const element = document.querySelector(selector);
            if (!element || !element.offsetParent || !element.getBoundingClientRect().top) {
                console.debug('FormErrorController: Element not found or not visible. Removing.', selector);
                FormErrorController.errors.delete(selector);
            }
        }
    }
    static getInstance() {
        if (!FormErrorController.instance) {
            FormErrorController.instance = new FormErrorController();
        }
        return FormErrorController.instance;
    }
}
FormErrorController.errors = new Map();
