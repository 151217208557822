export default class ToggleCheckBoxContent {
    constructor({ selector, content }) {
        this.selector = selector;
        this.content = content;
    }
    start() {
        const checkbox = document.querySelector(this.selector);
        const contentElement = document.querySelector(`#${this.content}`);
        if (!checkbox) {
            return console.error('ToggleCheckBoxContent: Element not found', this.selector);
        }
        if (!contentElement) {
            return console.error('ToggleCheckBoxContent: Element not found', this.content);
        }
        // Initial check to handle the default state
        contentElement.style.display = checkbox.checked ? 'block' : 'none';
        checkbox.addEventListener('change', () => {
            contentElement.style.display = checkbox.checked ? 'block' : 'none';
        });
    }
}
