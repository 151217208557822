/**
 * Handles the fieldsets in the document.
 * Allows to add more fieldsets and to hide them.
 */
export class ChildrenFieldsetsHandler {
    constructor(FIELDSET_KIDS) {
        this.FIELDSET_KIDS = FIELDSET_KIDS;
    }
    start() {
        if (this.FIELDSET_KIDS.length === 0) {
            return console.warn('Cancelled handleFieldsets() because no this.FIELDSET_KIDS were found.');
        }
        let nextFieldset = null;
        // let nextFieldset = null;
        const initiallyVisibleCount = 2;
        const fieldsets = [];
        for (let i = this.FIELDSET_KIDS.length - 1; i >= 0; i--) {
            const fieldset = this.FIELDSET_KIDS[i];
            const isLastFieldset = this.FIELDSET_KIDS.length - 1 === i;
            // hide all fieldsets but the first one
            try {
                nextFieldset = new KidsFieldset(fieldset, i, {
                    isLastFieldset,
                    fieldsets,
                    initiallyVisibleCount
                });
                fieldsets[i] = nextFieldset;
            }
            catch (e) {
                console.error(e);
            }
        }
    }
}
class KidsFieldset {
    constructor(fieldset, index, options) {
        var _a;
        this.index = index;
        this.options = options;
        this.changeFormNoticeSent = false;
        const isVisible = index < ((_a = options.initiallyVisibleCount) !== null && _a !== void 0 ? _a : 0);
        this.element = document.querySelector(`#${fieldset}`);
        if (!this.element) {
            throw new Error(`Cannot find element with id ${fieldset}.`);
        }
        this.actionsElement = this.createActionsElement();
        this.inputs = this.element.querySelectorAll('input, select');
        if (!isVisible) {
            this.element.style.display = 'none';
        }
        if (options.initiallyVisibleCount - 1 > index) {
            this.actionsElement.style.display = 'none';
        }
    }
    get previousFieldset() {
        var _a;
        return (_a = this.options.fieldsets[this.index - 1]) !== null && _a !== void 0 ? _a : null;
    }
    get nextFieldset() {
        var _a;
        return (_a = this.options.fieldsets[this.index + 1]) !== null && _a !== void 0 ? _a : null;
    }
    createActionsElement() {
        var _a, _b;
        const actions = [];
        if (this.nextFieldset) {
            actions.push('<button class="form-add-child btn" type="button">+ weiteres Kind hinzufügen</button>');
        }
        if (this.index >= this.options.initiallyVisibleCount) {
            actions.push('<button class="form-remove-child btn" type="button">- entfernen</button>');
        }
        const actionsElement = document.createElement('div');
        actionsElement.innerHTML = actions.join('');
        this.element.appendChild(actionsElement);
        if (this.nextFieldset) {
            (_a = this.element.querySelector('.form-add-child')) === null || _a === void 0 ? void 0 : _a.addEventListener('click', (e) => {
                e.preventDefault();
                this.showNextInstance();
            });
        }
        if (0 !== this.index) {
            (_b = this.element.querySelector('.form-remove-child')) === null || _b === void 0 ? void 0 : _b.addEventListener('click', (e) => {
                e.preventDefault();
                this.hide();
                this.activatePreviousInstance();
            });
        }
        return actionsElement;
    }
    show() {
        this.element.style.display = 'block';
        this.element.classList.add('active');
        this.actionsElement.style.display = 'block';
        this.inputs.forEach((input, index) => {
            if (input.tagName === 'SELECT') {
                input.setCustomValidity('Bitte wählen Sie eine Option aus');
                input.addEventListener('change', () => {
                    if (input.value === 'Bitte Schule wählen') {
                        input.setCustomValidity('Bitte wählen Sie eine Option aus');
                    }
                    else {
                        input.setCustomValidity('');
                    }
                });
            }
            if (index < 4) {
                input.required = true;
            }
        });
    }
    hide() {
        this.element.style.display = 'none';
        this.element.classList.remove('active');
        this.inputs.forEach((input, index) => {
            if (index < 4) {
                input.required = false;
            }
        });
    }
    showNextInstance() {
        if (!this.nextFieldset) {
            return console.error('Cannot show next fieldset, because there is none.');
        }
        this.element.classList.remove('active');
        this.actionsElement.style.display = 'none';
        this.nextFieldset.show();
    }
    activatePreviousInstance() {
        var _a;
        (_a = this.previousFieldset) === null || _a === void 0 ? void 0 : _a.show();
    }
}
