export default class ManageConditionalCheckboxes {
    constructor({ checkbox1Selector, checkbox2Selector, content1Selector, content2Selector }) {
        this.checkbox1Selector = checkbox1Selector;
        this.checkbox2Selector = checkbox2Selector;
        this.content1Selector = content1Selector;
        this.content2Selector = content2Selector;
    }
    start() {
        const checkbox1 = document.querySelector(this.checkbox1Selector);
        const checkbox2 = document.querySelector(this.checkbox2Selector);
        const content1 = document.querySelector(this.content1Selector);
        const content2 = document.querySelector(this.content2Selector);
        if (!checkbox1 || !checkbox2 || !content1 || !content2) {
            console.error('One or more elements not found');
            return;
        }
        const uncheckChildCheckboxes = (parentSelector) => {
            const childCheckboxes = document.querySelectorAll(`${parentSelector} input[type='checkbox']`);
            for (const checkbox of childCheckboxes) {
                checkbox.checked = false;
                const changeEvent = new Event('change');
                const clickEvent = new Event('click');
                checkbox.dispatchEvent(changeEvent);
                checkbox.dispatchEvent(clickEvent);
            }
        };
        const getLabel = (element) => element.closest('label') || element;
        const updateStates = () => {
            const label1 = getLabel(checkbox1);
            const label2 = getLabel(checkbox2);
            if (checkbox1.checked) {
                uncheckChildCheckboxes(this.content2Selector);
                label2.style.display = 'none';
                content1.style.display = 'block';
                content2.style.display = 'none';
            }
            else if (checkbox2.checked) {
                uncheckChildCheckboxes(this.content1Selector);
                label1.style.display = 'none';
                content2.style.display = 'block';
                content1.style.display = 'none';
            }
            else {
                uncheckChildCheckboxes(this.content2Selector);
                uncheckChildCheckboxes(this.content1Selector);
                label1.style.display = 'block';
                label2.style.display = 'block';
                content1.style.display = 'none';
                content2.style.display = 'none';
            }
        };
        checkbox1.addEventListener('change', updateStates);
        checkbox2.addEventListener('change', updateStates);
        // Initialize the states based on the current checkbox states
        updateStates();
    }
}
