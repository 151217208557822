import FormErrorController from '../FormErrorController';
import { getAge, isElementDisplayed } from '../FormUtils';
export default class RequireAgeToBeInRange {
    constructor(selector) {
        this.selector = selector;
        this.field = document.querySelector(selector);
    }
    start() {
        if (!this.field)
            return console.error('validateBirthDate: Element not found', this.selector);
        this.field.addEventListener('input', (e) => {
            const field = e.target;
            this.reportValidity(field);
        });
    }
    reportValidity(field = this.field) {
        if (!isElementDisplayed(field)) {
            FormErrorController.removeError(field);
            return;
        }
        const date = new Date(field.value);
        const age = getAge(date);
        if (age < 2) {
            FormErrorController.addError(field, 'Das Kind ist zu jung.');
            return 'Das Kind ist zu jung.';
        }
        if (age >= 22) {
            FormErrorController.addError(field, 'Das Kind ist zu alt.');
            return 'Das Kind ist zu alt.';
        }
        FormErrorController.removeError(field);
    }
    onSubmit() {
        if (!this.field)
            return console.error('validateBirthDate: Element not found', '#date-of-birth');
        return this.reportValidity(this.field);
    }
}
