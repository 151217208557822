export default class ReplaceStringWithLink {
    constructor(selector, searchString, href) {
        this.selector = selector;
        this.searchString = searchString;
        this.href = href;
    }
    start() {
        const element = document.querySelector(this.selector);
        if (!element) {
            console.warn(`Element with selector "${this.selector}" not found.`);
            return;
        }
        // Check that any of the children contain the search string
        const childNodes = Array.from(element.childNodes);
        for (const child of childNodes) {
            if (child.nodeType === Node.TEXT_NODE &&
                child.textContent &&
                child.textContent.includes(this.searchString)) {
                const regex = new RegExp(`(${this.searchString})`, 'gi');
                const replacement = document.createElement('span');
                replacement.innerHTML = child.textContent.replace(regex, `<a target="_blank" href="${this.href}">$1</a>`);
                child.replaceWith(replacement);
            }
            else if (child.nodeType === Node.ELEMENT_NODE) {
                // If it's an element, we can safely use innerHTML
                const childElement = child;
                const regex = new RegExp(`(${this.searchString})`, 'gi');
                childElement.innerHTML = childElement.innerHTML.replace(regex, `<a target="_blank" href="${this.href}">$1</a>`);
            }
        }
    }
}
