import { ManageIban } from '../utils/ManageIban';
import { PresetValidityMessage } from '../utils/PresetValidityMessage';
import RequireAgeToBeInRange from '../utils/RequireAgeToBeInRange';
import RequiredField from '../utils/RequiredField';
import SendFormWithServerSideErrorHandling from '../utils/SendFormWithServerSideErrorHandling';
import { RadioSelectionClearButton } from '../utils/RadioSelectionClearButton';
import FormRequestController from '../utils/FormRequestController';
import ValidateFieldsOnChange from '../utils/ValidateFieldsOnChange';
import ScrollUpAtSuccessMessage from '../utils/ScrollUpAtSuccessMessage';
import AddClassOnSubmit from '../utils/AddClassOnSubmit';
import RequireIBANIfAccountHolderSelected from '../utils/RequireIBANIfAccountHolderSelected';
import { ChildrenFieldsetsHandler } from '../utils/ChildrenFieldsetsHandler';
import { AddSubmitButtonLoadingState } from '../utils/AddSubmitButtonLoadingState';
import ReplaceStringWithLink from '../utils/ReplaceStringWithLink';
import SetRequiredIfCheckboxChecked from '../utils/SetRequiredIfCheckboxChecked';
import ToggleCheckBoxContent from '../utils/ToggleCheckBoxContent';
const SELECTOR = 'schulgeld-Geschwisterbonus-2621';
export const FormFields = {
    CHILD_1_BIRTH_DATE: `#${SELECTOR}-date-1`,
    CHILD_2_BIRTH_DATE: `#${SELECTOR}-date-2`,
    CHILD_3_BIRTH_DATE: `#${SELECTOR}-date-3`,
    CHILD_4_BIRTH_DATE: `#${SELECTOR}-date-4`,
    CHILD_5_BIRTH_DATE: `#${SELECTOR}-date-5`,
    IBAN: `#${SELECTOR}-text-27`,
    CAPTCHA: `#${SELECTOR}-captcha-1`,
    FIELDSET_2: `${SELECTOR}-fieldset-2`,
    FIELDSET_3: `${SELECTOR}-fieldset-3`,
    FIELDSET_4: `${SELECTOR}-fieldset-4`,
    FIELDSET_5: `${SELECTOR}-fieldset-5`,
    FIELDSET_6: `${SELECTOR}-fieldset-6`,
    SCHULAUSWAHL_1: `#${SELECTOR}-singleselect-1`,
    SCHULAUSWAHL_2: `#${SELECTOR}-singleselect-2`,
    DATENSCHUTZ_CHECKBOX: `#${SELECTOR}-checkbox-2`,
    ACCOUNT_SELECTOR_WRAPPER: `#${SELECTOR}-radiobutton-2`,
    PARENT_2_NAME: `#${SELECTOR}-text-39`,
    PARENT_2_EMAIL: `#${SELECTOR}-text-40`,
    PARENT_2_PHONE: `#${SELECTOR}-text-41`,
    ACCOUNT_HOLDER_PARENT_2: `#${SELECTOR}-radiobutton-2-1`,
};
export default class SchulgeldGeschwisterBonus {
    constructor() {
        this.SELECTOR = `${SELECTOR}`;
        this.features = [
            new ChildrenFieldsetsHandler([
                FormFields.FIELDSET_2,
                FormFields.FIELDSET_3,
                FormFields.FIELDSET_4,
                FormFields.FIELDSET_5,
                FormFields.FIELDSET_6,
            ]),
            new RequireAgeToBeInRange(FormFields.CHILD_1_BIRTH_DATE),
            new RequireAgeToBeInRange(FormFields.CHILD_2_BIRTH_DATE),
            new RequireAgeToBeInRange(FormFields.CHILD_3_BIRTH_DATE),
            new RequireAgeToBeInRange(FormFields.CHILD_4_BIRTH_DATE),
            new RequireAgeToBeInRange(FormFields.CHILD_5_BIRTH_DATE),
            new ManageIban(FormFields.ACCOUNT_SELECTOR_WRAPPER),
            new RequiredField(FormFields.SCHULAUSWAHL_1, 'Bitte wählen Sie eine Option aus', { placeholder: 'Bitte Schule wählen' }),
            new RequiredField(FormFields.SCHULAUSWAHL_2, 'Bitte wählen Sie eine Option aus', { placeholder: 'Bitte Schule wählen' }),
            new PresetValidityMessage(FormFields.DATENSCHUTZ_CHECKBOX, 'Bitte akzeptieren Sie die Datenschutzbestimmungen'),
            new ScrollUpAtSuccessMessage(`#${SELECTOR}`),
            new RequireIBANIfAccountHolderSelected(FormFields.IBAN, [
                `#${SELECTOR}-radiobutton-2-0`,
                `#${SELECTOR}-radiobutton-2-1`,
            ]),
            new SetRequiredIfCheckboxChecked(FormFields.PARENT_2_NAME, FormFields.ACCOUNT_HOLDER_PARENT_2),
            new SetRequiredIfCheckboxChecked(FormFields.PARENT_2_EMAIL, FormFields.ACCOUNT_HOLDER_PARENT_2),
            new SetRequiredIfCheckboxChecked(FormFields.PARENT_2_PHONE, FormFields.ACCOUNT_HOLDER_PARENT_2),
            new ToggleCheckBoxContent({ selector: `#${SELECTOR}-checkbox-3`, content: `${SELECTOR}-fieldset-7` }),
            new RadioSelectionClearButton(`#${SELECTOR}-radiobutton-2`, `#${SELECTOR}-radiobutton-2 .form-radio input`, {
                inputsToClearErrorsSelector: FormFields.IBAN,
            }),
            new AddClassOnSubmit(`#${SELECTOR}`, 'form--submitted'),
            new SendFormWithServerSideErrorHandling(FormFields.CAPTCHA),
            new ValidateFieldsOnChange(),
            new AddSubmitButtonLoadingState(`#${SELECTOR}`, 'div.actions > nav > div > div > span > button'),
            new ReplaceStringWithLink('.checkbox-terms.form-check-label', 'Datenschutzbedingungen', 'https://kseh.de/datenschutz')
        ];
        for (const feature of this.features) {
            // try catch is important here because we don't want a broken feature to impact the others
            try {
                if (feature.start) {
                    feature.start();
                }
            }
            catch (e) {
                console.error('GeschwisterBonus: Feature failed to start', e);
            }
        }
        const form = document.querySelector(`#${this.SELECTOR}`);
        if (!form)
            throw new Error(`GeschwisterBonus: Form with selector #${this.SELECTOR} not found.`);
        this.form = form;
        if (!form)
            return;
        new FormRequestController(this);
    }
    static isPresentOnPage() {
        return document.querySelector(`#${SELECTOR}`) !== null;
    }
}
