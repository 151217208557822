var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import FormErrorController from '../FormErrorController';
export default class SendFormWithServerSideErrorHandling {
    constructor(selector) {
        this.captcha = document.querySelector(selector);
        if (!this.captcha) {
            throw new Error(`SendFormWithServerSideErrorHandling: Element with selector ${selector} not found.`);
        }
    }
    start() {
        return __awaiter(this, void 0, void 0, function* () {
            this.captcha.required = true;
            this.captcha.addEventListener('change', () => {
                if (this.captcha.value) {
                    FormErrorController.removeError(this.captcha);
                }
                else {
                    FormErrorController.addError(this.captcha, 'Bitte geben Sie die Buchstaben zur Überprüfung ein.');
                }
            });
        });
    }
    onServerResponse(doc) {
        return __awaiter(this, void 0, void 0, function* () {
            const captcha = doc.querySelector('.captcha');
            if (!captcha)
                return;
            const captchaFieldset = captcha.parentElement;
            const errorChild = captchaFieldset === null || captchaFieldset === void 0 ? void 0 : captchaFieldset.querySelector('.error');
            if (errorChild) {
                console.error(errorChild.textContent.trim());
                FormErrorController.addError(this.captcha, errorChild.textContent.trim());
                return errorChild.textContent.trim();
            }
            FormErrorController.removeError(this.captcha);
        });
    }
}
