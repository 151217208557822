import { turnOffAutocomplete } from '../FormUtils';
import { ManageIban } from '../utils/ManageIban';
import { PresetValidityMessage } from '../utils/PresetValidityMessage';
import RequiredField from '../utils/RequiredField';
import RequireAtLeastOneFile from '../utils/RequireAtLeastOneFile';
import RequireAgeToBeInRange from '../utils/RequireAgeToBeInRange';
import { ShowErrorIfNotOneChecked } from '../utils/ShowErrorIfNotOneChecked';
import ScrollUpAtSuccessMessage from '../utils/ScrollUpAtSuccessMessage';
import SetRequiredIfCheckboxChecked from '../utils/SetRequiredIfCheckboxChecked';
import RequireIBANIfAccountHolderSelected from '../utils/RequireIBANIfAccountHolderSelected';
import AddClassOnSubmit from '../utils/AddClassOnSubmit';
import EnforceMaxInputTextLength from '../utils/EnforceMaxInputTextLength';
import { RadioSelectionClearButton } from '../utils/RadioSelectionClearButton';
import SendFormWithServerSideErrorHandling from '../utils/SendFormWithServerSideErrorHandling';
import FormRequestController from '../utils/FormRequestController';
import ValidateFieldsOnChange from '../utils/ValidateFieldsOnChange';
import { ChildrenFieldsetsHandler } from '../utils/ChildrenFieldsetsHandler';
import { AddSubmitButtonLoadingState } from '../utils/AddSubmitButtonLoadingState';
import ToggleCheckBoxContent from '../utils/ToggleCheckBoxContent';
import ManageConditionalCheckboxes from '../utils/ManageConditionalCheckboxes';
import ReplaceStringWithLink from '../utils/ReplaceStringWithLink';
import EnforceSingleCheckbox from '../utils/EnforceSingleCheckbox';
import { HideElementIfNotSelected } from '../utils/HideElementIfNotSelected';
const SELECTOR = 'schulgeld-SchulgeldermaessigungmitGeschwisterbonus-2624';
const FormFields = {
    JOB_CENTER_CHECKBOX: `#${SELECTOR}-checkbox-1`,
    JOB_CENTER_FILE_UPLOAD: `#${SELECTOR}-fileupload-1`,
    INCOME_PROOF_CHECKBOX: `#${SELECTOR}-checkbox-2`,
    PARENT_2_NAME: `#${SELECTOR}-text-30`,
    PARENT_2_JOB: `#${SELECTOR}-text-31`,
    PARENT_2_EMAIL: `#${SELECTOR}-email-2`,
    PARENT_2_PHONE: `#${SELECTOR}-telephone-1`,
    FIELDSET_2: `${SELECTOR}-fieldset-2`,
    FIELDSET_3: `${SELECTOR}-fieldset-3`,
    FIELDSET_4: `${SELECTOR}-fieldset-4`,
    FIELDSET_5: `${SELECTOR}-fieldset-5`,
    FIELDSET_6: `${SELECTOR}-fieldset-6`,
    SCHULAUSWAHL_1: `#${SELECTOR}-singleselect-1`,
    SCHULAUSWAHL_2: `#${SELECTOR}-singleselect-3`,
    CHILD_1_BIRTH_DATE: `#${SELECTOR}-date-1`,
    CHILD_2_BIRTH_DATE: `#${SELECTOR}-date-2`,
    CHILD_3_BIRTH_DATE: `#${SELECTOR}-date-3`,
    CHILD_4_BIRTH_DATE: `#${SELECTOR}-date-4`,
    CHILD_5_BIRTH_DATE: `#${SELECTOR}-date-5`,
    INCOME_PROOF_NOTHING_APPLIES_CHECKBOX: `#${SELECTOR}-checkbox-25`,
    INCOME_PROOF_NOTHING_APPLIES_FILE_UPLOAD: `#${SELECTOR}-fileupload-2`,
    INCOME_PROOF_NOTHING_APPLIES_JUSTIFICATION: `#${SELECTOR}-text-35`,
    ACCOUNT_SELECTOR_WRAPPER: `#${SELECTOR}-radiobutton-2`,
    ACCOUNT_HOLDER_PARENT_2: `#${SELECTOR}-radiobutton-2-1`,
    IBAN: `#${SELECTOR}-text-27`,
    CAPTCHA: `#${SELECTOR}-captcha-1`,
};
export default class SchulgeldSchulgeldErmaessigungMitGeschwisterbonus {
    constructor() {
        this.features = [
            new ChildrenFieldsetsHandler([
                FormFields.FIELDSET_2,
                FormFields.FIELDSET_3,
                FormFields.FIELDSET_4,
                FormFields.FIELDSET_5,
                FormFields.FIELDSET_6,
            ]),
            new RequireAgeToBeInRange(FormFields.CHILD_1_BIRTH_DATE),
            new RequireAgeToBeInRange(FormFields.CHILD_2_BIRTH_DATE),
            new RequireAgeToBeInRange(FormFields.CHILD_3_BIRTH_DATE),
            new RequireAgeToBeInRange(FormFields.CHILD_4_BIRTH_DATE),
            new RequireAgeToBeInRange(FormFields.CHILD_5_BIRTH_DATE),
            new ShowErrorIfNotOneChecked([FormFields.INCOME_PROOF_CHECKBOX, FormFields.JOB_CENTER_CHECKBOX]),
            new SetRequiredIfCheckboxChecked(FormFields.JOB_CENTER_FILE_UPLOAD, FormFields.JOB_CENTER_CHECKBOX),
            new SetRequiredIfCheckboxChecked(FormFields.INCOME_PROOF_NOTHING_APPLIES_FILE_UPLOAD, FormFields.INCOME_PROOF_NOTHING_APPLIES_CHECKBOX),
            new RequireAtLeastOneFile(FormFields.INCOME_PROOF_CHECKBOX),
            new ManageIban(FormFields.ACCOUNT_SELECTOR_WRAPPER),
            new RequiredField(FormFields.SCHULAUSWAHL_1, 'Bitte wählen Sie eine Option aus', { placeholder: 'Bitte Schule wählen' }),
            new RequiredField(FormFields.SCHULAUSWAHL_2, 'Bitte wählen Sie eine Option aus', { placeholder: 'Bitte Schule wählen' }),
            new PresetValidityMessage(`#${SELECTOR}-checkbox-24`, 'Bitte akzeptieren Sie die Datenschutzbestimmungen'),
            new ScrollUpAtSuccessMessage(`#${SELECTOR}`),
            new RequireIBANIfAccountHolderSelected(FormFields.IBAN, [
                `#${SELECTOR}-radiobutton-2-0`,
                `#${SELECTOR}-radiobutton-2-1`
            ]),
            new SetRequiredIfCheckboxChecked(FormFields.PARENT_2_NAME, FormFields.ACCOUNT_HOLDER_PARENT_2),
            new SetRequiredIfCheckboxChecked(FormFields.PARENT_2_JOB, FormFields.ACCOUNT_HOLDER_PARENT_2),
            new SetRequiredIfCheckboxChecked(FormFields.PARENT_2_EMAIL, FormFields.ACCOUNT_HOLDER_PARENT_2),
            new SetRequiredIfCheckboxChecked(FormFields.PARENT_2_PHONE, FormFields.ACCOUNT_HOLDER_PARENT_2),
            new AddClassOnSubmit(`#${SELECTOR}`, 'form--submitted'),
            new EnforceMaxInputTextLength(FormFields.INCOME_PROOF_NOTHING_APPLIES_JUSTIFICATION, 120),
            new RadioSelectionClearButton(`#${SELECTOR}-radiobutton-2`, `#${SELECTOR}-radiobutton-2 .form-radio input`, {
                inputsToClearErrorsSelector: FormFields.IBAN
            }),
            new SendFormWithServerSideErrorHandling(FormFields.CAPTCHA),
            new ValidateFieldsOnChange(),
            new AddSubmitButtonLoadingState(`#${SELECTOR}`, 'div.actions > nav > div > div > span > button'),
            new ToggleCheckBoxContent({ selector: `#${SELECTOR}-checkbox-3`, content: `${SELECTOR}-fieldset-7` }),
            new ManageConditionalCheckboxes({
                checkbox1Selector: `#${SELECTOR}-checkbox-1`,
                checkbox2Selector: `#${SELECTOR}-checkbox-2`,
                content1Selector: `#${SELECTOR}-fieldset-8`,
                content2Selector: `#${SELECTOR}-fieldset-9`
            }),
            new ReplaceStringWithLink('.checkbox-terms.form-check-label', 'Datenschutzbedingungen', 'https://kseh.de/datenschutz'),
            new EnforceSingleCheckbox('.checkboxes-no-income', 'In dieser Kategorie kann jeweils nur ein Kontrollkästchen aktiv sein.' +
                'Befinden Sie sich vielleicht im Dienst des JobCenters?'),
            new HideElementIfNotSelected(`#${SELECTOR}-fieldset-9`)
        ];
        for (const feature of this.features) {
            try {
                if (feature.start) {
                    feature.start();
                }
            }
            catch (e) {
                console.error('SchulgeldErmaessigung: Feature failed to start', e);
            }
        }
        turnOffAutocomplete([`#${SELECTOR}-text-7`]);
        const form = document.querySelector(`#${SELECTOR}`);
        if (!form)
            throw new Error(`SchulgeldErmaessigung: Form with selector #${SELECTOR} not found.`);
        this.form = form;
        new FormRequestController(this);
    }
    static isPresentOnPage() {
        return document.querySelector(`#${SELECTOR}`) !== null;
    }
}
