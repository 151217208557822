/**
 * Hides the file upload input if the checkbox is not selected.
 */
export class HideElementIfNotSelected {
    constructor(selector) {
        this.selector = selector;
    }
    start() {
        const rows = Array.from(document.querySelectorAll(`${this.selector} > .row`));
        const objects = rows
            .map((el) => {
            const checkboxes = Array.from(el.querySelectorAll('.checkbox')).map((el) => {
                return el.querySelector('input[type=checkbox]');
            });
            const fileUploads = el.querySelectorAll('[type=file]');
            return {
                checkboxes,
                fileUploads
            };
        })
            .filter(({ checkboxes, fileUploads }) => {
            return checkboxes.length === 2 && fileUploads;
        });
        for (const { checkboxes, fileUploads } of objects) {
            const setUploadDisplay = () => {
                if (checkboxes[0].checked) {
                    fileUploads[0].parentElement.parentElement.style.display = 'block';
                    fileUploads[0].required = true;
                }
                else {
                    fileUploads[0].parentElement.parentElement.style.display = 'none';
                    fileUploads[0].required = false;
                    this.removeUploadedFiles(fileUploads[0]);
                }
                if (checkboxes[1].checked) {
                    fileUploads[1].parentElement.parentElement.style.display = 'block';
                    fileUploads[1].required = true;
                }
                else {
                    fileUploads[1].parentElement.parentElement.style.display = 'none';
                    fileUploads[1].required = false;
                    this.removeUploadedFiles(fileUploads[1]);
                }
            };
            for (const checkbox of checkboxes) {
                checkbox.addEventListener('click', () => {
                    setUploadDisplay();
                });
            }
            setUploadDisplay();
        }
    }
    removeUploadedFiles(input) {
        input.value = '';
        const multiUploadFiles = input.nextSibling;
        // Remove all uploaded files
        while (multiUploadFiles.firstChild) {
            multiUploadFiles.removeChild(multiUploadFiles.firstChild);
        }
    }
}
