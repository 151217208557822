/**
 * Handles the fieldsets in the document.
 * Allows to add more fieldsets and to hide them.
 */
export class AddSubmitButtonLoadingState {
    constructor(formSelector, buttonSelector) {
        this.formSelector = formSelector;
        this.buttonSelector = buttonSelector;
    }
    start() {
        const form = document.querySelector(this.formSelector);
        form.addEventListener('formdata', (event) => {
            const target = event.target;
            const submitButton = target.querySelector(this.buttonSelector);
            submitButton.disabled = true;
            submitButton.innerHTML = submitButton.innerHTML.replace('Absenden', 'Bitte warten...');
        });
        form.addEventListener('invalid', (event) => {
            const target = event.target;
            const submitButton = target.querySelector(this.buttonSelector);
            submitButton.disabled = false;
            submitButton.classList.remove('.next.submit[type=submit]');
            submitButton.textContent = submitButton.textContent.replace('Bitte warten...', 'Absenden');
        });
    }
}
