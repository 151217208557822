import './css/main.css';
import { initAnchorToModal } from './ts/modules/modal/anchorToModal';
import { initCookie, initCookieDetails } from './ts/modules/cookie';
import { initDesktopMenu } from './ts/modules/desktopMenu';
import { initHeader } from './ts/modules/header';
import { initIndexNav } from './ts/modules/indexNav';
import { initLightbox } from './ts/modules/lightbox';
import { initMobileMenu } from './ts/modules/mobileMenu';
import { initPaceWithoutAnimation } from './ts/modules/pace';
import { ParamsDebugger } from './ts/classes/ParamsDebugger';
import { initIntroAnimation } from './ts/modules/animations/introAnimation';
import { initCheckAmountMenuItem } from './ts/modules/checkAmountMenuItem';
import { initCheckIllustrationCalendar } from './ts/modules/checkIllustartionCalendar';
import { initEmailCreator } from './ts/modules/emailCreator';
import { initCheckErrorInForm } from './ts/modules/form';
import { initPDFForm } from './ts/modules/form/index';
import { initGoogleMaps } from './ts/modules/googleMaps';
import { initHeroSlider } from './ts/modules/heroSlider';
import { initVorstand } from './ts/modules/pages/vorstand';
import { initProtectedArea } from './ts/modules/protectedArea';
import { initSmoothScroll } from './ts/modules/scrollToTop';
import { initSearch } from './ts/modules/search';
import { initShare } from './ts/modules/share';
import { initSidebar } from './ts/modules/sidebar';
import { initSignature } from './ts/modules/signature';
import { initTooltipTippy } from './ts/modules/tooltipTippy';
import { initVideoGrid } from './ts/modules/videogrid';
import { initCardModal } from './ts/modules/modal/cardModal';
import { initMarqueeSpanTransformation } from './ts/modules/marquee';
document.addEventListener('DOMContentLoaded', () => {
    initPDFForm();
    initPaceWithoutAnimation();
    initMarqueeSpanTransformation();
    initCheckAmountMenuItem();
    initCookie();
    initHeroSlider();
    // initReadMore();
    initIntroAnimation();
    initSidebar();
    initCookieDetails();
    initMobileMenu();
    initDesktopMenu();
    initSmoothScroll();
    initLightbox({ selector: '.ce-textpic img' });
    initLightbox({ selector: '.img__lightbox' });
    initLightbox({ selector: '.news-textimg__img img' });
    initHeader();
    initSearch();
    // Content Element Specific
    initVideoGrid();
    const quotesElement = document.getElementsByClassName('quote');
    if (quotesElement.length > 0) {
        import('./ts/modules/animations/quotesAnimation').then(({ initQuotesAnimation }) => {
            initQuotesAnimation().catch(console.error);
        });
    }
    const imageGrid = document.getElementsByClassName('quote');
    if (imageGrid.length > 0) {
        import('./ts/modules/animations/videogridAnimation').then(({ initVideoGridAnimation }) => {
            initVideoGridAnimation().catch(console.error);
        });
    }
    initShare();
    initIndexNav();
    initAnchorToModal();
    initCardModal();
    initEmailCreator('.ce-table');
    initGoogleMaps('.gmaps-wrapper');
    initCheckErrorInForm('.has-error');
    initTooltipTippy('[data-tippy-content]');
    initProtectedArea({ slugProtectedArea: '/interner-bereich' });
    initSignature();
    initCheckIllustrationCalendar();
    // Page specific
    initVorstand();
    const isDebugColorActive = getComputedStyle(document.documentElement)
        .getPropertyValue('--debug-color')
        .trim()
        .replace(/['"]+/g, '') || '';
    if ((isDebugColorActive === 'true' || isDebugColorActive === '1') && !(window.innerWidth < 1024)) {
        new ParamsDebugger({ show: true });
    }
});
